<template>

  <div class="col-lg-10 col-md-10 ">
    <div class="card">
      <!-- 媒體庫功能區塊 -->
      <div class="card-body p-0">
        <div class="user-tabing p-3">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <div class="d-flex m-1">
              <router-link :to="{ name: 'social.profileimage' }">
                <button type="button" class="btn-outline-primary btn rounded-pill me-1">
                  {{ $t('label.photo') }}
                </button>
              </router-link>
              <router-link :to="{ name: 'social.profilevideo' }">
                <button type="button" class="btn-primary btn rounded-pill me-1">
                  {{ $t('label.video') }}
                </button>
              </router-link>
            </div> -->
            <!-- <div class="d-flex align-items-center">
                <tooltip tag="a" type="" href="/media-list" className="me-3 iq-notify bg-soft-primary rounded"
                  tooltipPlacement="top" data-bs-original-title="類別"><i class="ri-folder-line"></i></tooltip>
                <div class="dropdown">
                  <tooltip tag="span" type="" className="dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false" tooltipPlacement="top" :data-bs-original-title="$t('label.displayMore')"><i
                      class="ri-more-fill h4 "></i></tooltip>
                  <div class="dropdown-menu dropdown-menu-right" style="">
                    <a class="dropdown-item" href="#"><i
                        class="ri-thumb-up-line me-2"></i>{{$t('label.mostLikes')}}</a><a class="dropdown-item"
                      href="#"><i class="ri-money-dollar-circle-line me-2"></i>{{$t('label.maximumRewardAmount')}}</a><a
                      class="dropdown-item" href="#"><i
                        class="ri-sort-asc me-2"></i>{{$t('label.ascendingOrder')}}</a><a class="dropdown-item"
                      href="#"><i class="ri-sort-desc me-2"></i>{{$t('label.descendingOrder')}}</a>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <!---->
    </div>

    <div v-if="profileImages.length >=1" class="row " v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult" v-viewer="viewOptions" infinite-scroll-delay="1000" :infinite-scroll-distance="50">
      <div class="col-4 mb-2 col-lg-3 col-md-4" v-for="(item, index) in profileImages" :key="index">
        <div class="user-images position-relative overflow-hidden">
          <a @click="show()" href="javascript:void(0)">
            <!-- <img :src="item.media_square_url" :data-source="item.media_url" class="img-fluid rounded" /> -->
            <!-- <Xgplayer :config="video_url" ref="videoPlayer" @player="Player = $event" :last-index-of="" /> -->
            <videoPlay :data-source="item.media_url" class="video img-fluid rounded" v-bind="getOption(item)" />
          </a>
          <!-- <div class="image-hover-data">
            <div class="product-elements-icon">
              <ul class="d-flex align-items-center m-0 p-0 list-inline">
                <li v-for="(item, index1) in item.otherInfo" :key="index1">
                  <a href="#" class="pe-3 text-white">
                    {{ item.value }} <i :class="iconClass[index1]"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- <a href="#" class="image-edit-btn" data-toggle="tooltip" data-placement="top" title=""
            data-bs-original-title="Edit or Remove"><i class="ri-edit-2-fill"></i></a> -->
        </div>
      </div>
    </div>
    <div v-else class="card card-block card-stretch card-height">
      <div class="card-body text-center">{{ $t('label.noInformation') }}</div>
    </div>
    <!-- <div v-if="commomData.listLoader" class="d-block text-center mb-0 pb-0">
      <img src="@/assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
    </div> -->
  </div>
</template>
<script>
// import { scroll } from '@/config/pluginScroll'
import { getMedia } from '@/api/media'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { directive as viewer } from 'v-viewer'
import videoPlay from 'vue3-video-play' // 引入组件
export default {
  name: 'ProfileVideo',
  mounted() {
    this.$nextTick(() => {
      // scroll.onscroll()
    })
  },
  created() {
    this.getRows()
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  components: {
    videoPlay
  },
  directives: {
    viewer: viewer({
      debug: true
    })
  },
  data() {
    return {
      viewOptions: {
        toolbar: true,
        url: 'data-source'
      },
      commomData: commomData(),
      isFetching: false, // 新增變量來追蹤是否正在請求
      primaryKey: 'media_category_id',
      profileImages: [],
      iconClass: ['ri-thumb-up-line', 'ri-chat-3-line']
    }
  },
  methods: {
    getOption(r) {
      return {
        width: '100%', //播放器高度
        height: '10rem', //播放器高度
        color: '#818181', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false, //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
        title: '', //视频名称
        src: r.media_url, //视频源
        poster: r.media_square_url, //封面
        preload: 'meta'
      }
    },
    show() {
      // const viewer = this.$el.querySelector('.video').$viewer
      // viewer.show()
    },
    async getRows() {
      if (this.commomData.noResult || this.isFetching) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.type = 'video'
      this.isFetching = true // 鎖定請求

      await getMedia(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.profileImages = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.profileImages.length) {
            this.profileImages.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.profileImages.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: err.response.data.message
          })
        })
        .finally(() => {
          this.isFetching = false // 請求完成後解鎖
        })
    }
  }
}
</script>
